<template>
    <v-container fluid>
      <h1 class="ma-8"
      >
        {{inspectionSelected.name ? inspectionSelected.name : ''}}
      </h1>
        <v-card class="mx-auto mt-12 pa-3 gray lighten-1"
                flat
                style="
                       border: none;
                       border-radius: 10px"
        >
          <v-card-title class="d-flex align-start">
            <div class="d-block">
              <span style="font-weight: bold; font-size: 12px">{{$t('survey.fields.general_data') | toFormatCase('upper')}}</span>
              <v-list-item-title style="font-size: 20px;
                                              font-weight: 300;"
              >
                {{$t('survey.titles.punctuationSection')}}
                <span class="font-weight-black neuter--text text--lighten-1">{{inspectionSelected.surveyAnswerPunctuation}}</span>
                {{$t('survey.titles.punctuationSection1')}}
                <span class="font-weight-black neuter--text text--lighten-1">{{inspectionSelected.surveyPunctuation}}</span>
                {{$t('survey.titles.punctuationSection2')}}
              </v-list-item-title>
              <!--<div style="font-size: 15px; opacity: 50%; margin-top: -7px">
                  <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs"
                            v-on="on">
                          <span v-if="surveyAverage() > 0 && surveyAverage() <= 20 "
                                style="border-radius: 3em ;padding: 0 30px 0 30px; margin-top: 10px;" class="error"/>
                          <span v-if="surveyAverage() > 20 && surveyAverage() <= 40 "
                                style="padding: 0 30px 0 30px; margin-top: 10px;" class="warning"/>
                          <span v-if="surveyAverage() > 40 && surveyAverage() <= 60 "
                                style="padding: 0 30px 0 30px; margin-top: 10px;" class="primary"/>
                          <span v-if="surveyAverage() > 60 && surveyAverage() <= 80 "
                                style="padding: 0 30px 0 30px; margin-top: 10px;" class="gray"/>
                          <span v-if="surveyAverage() > 80 && surveyAverage() <= 100 "
                                style="border-radius:3em; padding: 0 30px 0 30px; margin-top: 10px;" class="secondary"/>
                      </span>
                      </template>
                      <span>{{surveyAverage()}}% {{$t('inspections.labels.complete_of')}} 100%</span>
                  </v-tooltip>
              </div>-->
            </div>
            <v-spacer></v-spacer>
            <div class="d-block text-center">
              <div style="font-size: 15px; opacity: 50%; margin-top: -7px;">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs"
                                          v-on="on">
                                        <!--<v-badge v-if="surveyAverage() > 0 && surveyAverage() <= 20 "
                                                 color="error" overlap bordered
                                        >-->
                                            <span class="neuter--text" style="font-weight: bold;
                                                         font-size: 54px;"
                                            >
                                                {{surveyAverage() | round(1)}}%
                                            </span>
                                      <!--</v-badge>
                                      <v-badge v-else-if="surveyAverage() > 20 && surveyAverage() <= 40 "
                                               color="warning" overlap bordered
                                      >
                                          <span style="font-weight: bold;
                                                       font-size: 54px;
                                                       color: rgba(3,172,140,0.43);"
                                          >
                                              {{surveyAverage()}}%
                                          </span>
                                      </v-badge>
                                      <v-badge v-else-if="surveyAverage() > 40 && surveyAverage() <= 60 "
                                               color="primary" overlap bordered
                                      >
                                          <span style="font-weight: bold;
                                                       font-size: 54px;
                                                       color: rgba(3,172,140,0.43);"
                                          >
                                              {{surveyAverage()}}%
                                          </span>
                                      </v-badge>
                                      <v-badge v-else-if="surveyAverage() > 60 && surveyAverage() <= 80 "
                                               color="gray" overlap bordered
                                      >
                                          <span style="font-weight: bold;
                                                       font-size: 54px;
                                                       color: rgba(3,172,140,0.43);"
                                          >
                                              {{surveyAverage()}}%
                                          </span>
                                      </v-badge>
                                      <v-badge v-else
                                               color="primary" overlap bordered
                                      >
                                          <span style="font-weight: bold;
                                                       font-size: 54px;
                                                       color: rgba(3,172,140,0.43);"
                                          >
                                              {{surveyAverage()}}%
                                          </span>
                                      </v-badge>-->
                                    </span>
                  </template>
                  <span>{{surveyAverage() | round(1)}}% {{$t('inspections.labels.complete_of')}} 100%</span>
                </v-tooltip>
              </div>
            </div>
          </v-card-title>

            <v-card-text class="mt-5">
                <v-row>
                    <v-col cols="2">
                        <span class="neuter--text" style="font-weight: bold; font-size: 18px;">{{$t('survey.fields.date')+": "}}</span>
                      <v-chip small
                              class="ma-1"
                      >
                        {{getDateFormat(inspectionSelected.data)}}
                      </v-chip>
                    </v-col>
                    <v-col cols="5">
                        <span class="neuter--text" style="font-weight: bold; font-size: 18px;">{{$t('documents.fields_add.locations')+": "}}</span>
                        <v-chip small
                                class="ma-1"
                        >
                            {{location_origin ? location_origin.name: ''}}
                        </v-chip>
                    </v-col>
                    <v-col cols="5">
                        <span class="neuter--text" style="font-weight: bold; font-size: 18px;">{{$t('documents.fields_add.storage_device')+": "}}</span>
                        <v-chip v-for="(local, index) in inspectionSelected.locations"
                                :key="index"
                                small
                                class="ma-1"
                        >
                            {{ local.control_label }}
                        </v-chip>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <template v-for="sectionValues in inspectionSelected.questionSectionList"
                  v-if="ready"
        >
            <v-card class="mx-auto mt-10 pa-3"
                    flat
                    style="border: none;
                           border-radius: 10px"
            >
                <v-card-title class="d-flex align-start"
                              style="border-radius: 8px;">
                    <v-list-item-content class="d-block text-left">
                        <div class="d-block">
                            <v-list-item-title class="neuter--text" style="font-weight: bold; font-size: 20px;"
                            >
                                {{sectionValues["name"]}}
                            </v-list-item-title>
                            <v-list-item-title class="neuter--text" style="font-size: 15px;
                                              font-weight: 300;"
                            >
                                {{$t('survey.titles.punctuationSection')}}
                                <strong class="font-weight-black neuter--text text--lighten-1">{{ sectionValues.questionSectionAnswerPunctuation | round(2) }}</strong>
                                {{$t('survey.titles.punctuationSection1')}}
                                <strong class="font-weight-black neuter--text text--lighten-1">{{sectionValues.punctuation | round(2) }}</strong>
                                {{$t('survey.titles.punctuationSection2')}}
                            </v-list-item-title>
                        </div>
                    </v-list-item-content>
                    <v-spacer></v-spacer>
                    <span style="font-weight: bold;
                              font-size: 25px;
                              color: rgb(3,172,140);
                              display: flex;
                              align-items: flex-end;
                              flex-direction: column;
                              text-align: center"
                    >
                        <span style="font-size: 10px; opacity: 50%; margin-top: -5px">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs"
                                          v-on="on">
                                        <!--<v-badge v-if="sectionAverage(sectionValues) > 0 && sectionAverage(sectionValues) <= 20 "
                                              color="error" overlap dot bordered
                                        >-->
                                            <span class="neuter--text" style="font-size: 30px">{{sectionAverage(sectionValues) | round(1)}}%</span>
                                        <!--</v-badge>
                                        <v-badge v-else-if="sectionAverage(sectionValues) > 20 && sectionAverage(sectionValues) <= 40 "
                                               color="warning" overlap dot bordered
                                        >
                                            <span style="font-size: 30px">{{sectionAverage(sectionValues)}}%</span>
                                        </v-badge>
                                        <v-badge v-else-if="sectionAverage(sectionValues) > 40 && sectionAverage(sectionValues) <= 60 "
                                               color="primary" overlap dot bordered
                                        >
                                            <span style="font-size: 30px">{{sectionAverage(sectionValues)}}%</span>
                                        </v-badge>
                                        <v-badge v-else-if="sectionAverage(sectionValues) > 60 && sectionAverage(sectionValues) <= 80 "
                                               color="gray" overlap dot bordered
                                        >
                                            <span style="font-size: 30px">{{sectionAverage(sectionValues)}}%</span>
                                        </v-badge>
                                        <v-badge v-else
                                               color="primary" overlap dot bordered
                                        >
                                            <span style="font-size: 30px">{{sectionAverage(sectionValues)}}%</span>
                                        </v-badge>-->
                                    </span>
                                </template>
                                    <span>{{sectionAverage(sectionValues) | round(1)}}% {{$t('inspections.labels.complete_of')}} 100%</span>
                            </v-tooltip>
                        </span>

                        <v-tooltip
                                bottom
                                open-on-click
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs"
                                       v-on="on"
                                       x-small
                                       outlined
                                       text
                                       color="primary"
                                       class="mt-1">
                                    {{$t('inspections.labels.responsable')}}
                                </v-btn>
                            </template>
                            <span v-for="(responsable, index) in sectionValues.responsables" :key="index">
                                {{responsable.name}} <br>
                            </span>
                        </v-tooltip>
                    </span>
                </v-card-title>
                <v-lazy :options="{ threshold: 1.0 }"
                        min-height="200"
                        transition="fade-transition"
                >
                    <v-card-text class="mt-5 pa-0" :ref="sectionValues.id">
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th style="width: 300px"
                                        class="text-left table-th-style"
                                    >
                                        {{ $t('inspections.labels.questions_table') }}
                                    </th>
                                    <th style="width: 300px"
                                        class="text-left table-th-style"
                                    >
                                        {{ $t('inspections.labels.answers_table') }}
                                    </th>
                                    <th style="width: 150px"
                                        class="text-center table-th-style"
                                    >
                                        {{ $t('inspections.labels.actions_table') }}
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr height="70px"
                                    v-for="(item, index) in sectionValues.questions" v-bind:key="index"
                                    v-if="item.show === true || !item.extraQuestionFatherRef"
                                >
                                    <!---->
                                    <td style="font-size: 20px; color: #0f0d0d">
                                        <span class="d-flex pt-5 pb-5">
                                            <strong>P.{{index+1}} </strong>
                                            <read-more :classes="'mb-0'"
                                                       :text="item.question"
                                                       :max-chars="50"
                                                       :font-size="20"
                                            />
                                        </span>
                                    </td>
                                    <td style="font-size: 20px; color: #0f0d0d;">
                                        <span v-if="item.answers && item.answers.length > 0">
                                             <span v-if="transformAnswers(item, item.answers) && transformAnswers(item, item.answers) !== 'null' && transformAnswers(item, item.answers) !== ''" class="d-flex pt-5 pb-5">
                                                  <strong>R: </strong>
                                                  <read-more :classes="'mb-0'"
                                                             :text="transformAnswers(item, item.answers)"
                                                             :max-chars="50"
                                                             :font-size="20"
                                                  />
                                             </span>
                                             <span v-else>
                                                 <v-chip small
                                                         disabled
                                                 >
                                                     {{ $t('inspections.labels.empty_answer') }}
                                                 </v-chip>
                                            </span>
                                        </span>
                                        <span class="mt-10" v-else>
                                            <v-chip small
                                                    disabled
                                            >
                                                {{ $t('inspections.labels.empty_answer') }}
                                            </v-chip>
                                        </span>
                                    </td>
                                    <td>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-badge
                                                        class="ma-2"
                                                        color="secondary accent-4"
                                                        :content="item.evidences.length > 0 && item.documents.length > 0 ? item.documents.length : item.evidences && item.evidences.length > 0 ? item.evidences.length : item.documents && item.documents.length > 0 ? item.documents.length : 0"
                                                        :value="(item.evidences.length > 0 || item.documents.length > 0)"
                                                        overlap
                                                >
                                                    <v-chip
                                                            @click="launchAction(itemsActions[1].action, item)"
                                                            :disabled="!(item.evidences.length > 0 || item.documents.length > 0) || loadingMoreOptions[item.id]"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                    >
                                                        <v-progress-circular
                                                          style="position: absolute;
                                                                 margin-left: 1px;
                                                                 height: 25px;"
                                                            :size="14"
                                                            :width="3"
                                                            v-show="loadingMoreOptions[item.id]"
                                                            color="secondary accent-5"
                                                            indeterminate
                                                            rounded
                                                        />
                                                        <v-icon>
                                                            mdi-file-document-outline
                                                        </v-icon>
                                                        <!--EVIDENCIAS-->
                                                    </v-chip>
                                                </v-badge>
                                            </template>
                                            <span>{{ $t('inspections.actions.download_evidences') }}</span>
                                        </v-tooltip>

                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-chip @click="openComment(item)"
                                                        :disabled="!(item.coments && item.coments.length > 0)"
                                                        class="ma-1"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                >
                                                    <v-icon>
                                                        mdi-comment-text-outline
                                                    </v-icon>
                                                    <!--COMENTARIOS-->
                                                </v-chip>
                                            </template>
                                            <span>{{ $t('inspections.actions.see_comments') }}</span>
                                        </v-tooltip>

                                        <!--<v-menu style="border-radius: 8px;" v-if="checkAccess(sectionValues)"
                                                allow-overflow>
                                            <template v-slot:activator="{ on: menu, attrs }">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on: tooltip }">
                                                        <v-chip
                                                                v-bind="attrs"
                                                                v-on="{ ...tooltip, ...menu }"
                                                                class="ma-1"
                                                        >
                                                            <v-icon>mdi-dots-horizontal</v-icon>
                                                        </v-chip>
                                                    </template>
                                                    <span>{{ $t('inspections.actions.more_options') }}</span>
                                                </v-tooltip>
                                            </template>
                                            <v-list>
                                                <v-list-item
                                                >
                                                    <v-btn @click.prevent="launchAction(itemsActions[0].action, item)"
                                                           large
                                                           text
                                                           width="250"
                                                           class="d-flex justify-start"
                                                    >
                                                        <v-icon left>
                                                            {{itemsActions[0].icon}}
                                                        </v-icon>
                                                        {{ itemsActions[0].title | toFormatCase('upper') }}
                                                    </v-btn>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>-->
                                    </td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card-text>
                </v-lazy>
            </v-card>
        </template>

        <v-fade-transition mode="out-in" v-if="inspectionSelected.signaturesPlaced && inspectionSelected.signaturesPlaced.length > 0">
            <v-row class="d-block text-center">
                <v-col cols="12">
                    <v-card class="mx-auto mr-7 ml-8 mt-10 pa-3" elevation="0">
                        <v-img
                                :src="`${inspectionSelected.signaturesPlaced[0].signature.s3_url}`"
                                height="240"
                                contain
                        />
                    </v-card>
                </v-col>
                <v-col cols="12">
                    <v-card class="mx-auto mr-7 ml-8 pa-3" elevation="0">
                        {{inspectionSelected.signaturesPlaced[0].description}}
                    </v-card>
                </v-col>
            </v-row>
        </v-fade-transition>

        <v-row>
            <v-col cols="12" class="text-right pa-6">
                <v-spacer></v-spacer>
                <v-btn elevation="0" color="primary" class="t-bw-primary--text" @click="back()">
                    {{$t('general.buttons.back')}}
                </v-btn>
            </v-col>
        </v-row>

        <!---------------------------------------------------------------------------------------------------------->

        <v-row justify="center">
            <v-dialog v-model="showDialogComment"
                      max-width="670"
            >
                <v-card>
                    <v-card-title class="headline secondary t-bw-secondary--text">
                        {{questionData.question}}
                      <v-chip color="primary" text-color="t-bw-primary">{{$t('inspections.labels.comments') | toFormatCase('upper')}}</v-chip>
                    </v-card-title>

                    <v-card-text class="pa-6">
                        <read-more :classes="'pt-2'"
                                   :text="questionData.coments"
                                   :max-chars="140"
                                   :font-size="18"
                        />
                    </v-card-text>

                    <v-card-actions class="pa-6">
                        <v-spacer></v-spacer>
                        <v-btn class="ma-3 t-bw-primary--text"
                               color="primary"
                               elevation="0"
                               @click="showDialogComment = false"
                        >
                            {{$t('general.buttons.back')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

        <v-row justify="center">
            <v-dialog v-if="editDialog"
                      v-model="editDialog"
                      persistent
                      max-width="800"
            >
                <v-card style="border-radius: 8px" class="pa-3">
                    <v-card-title class="headline ma-2"
                                  style="background-color: rgba(135,135,135,0.06);
                                         border-radius: 8px;
                                         display: flex;
                                         flex-wrap: nowrap;
                                         align-content: flex-end;
                                         flex-direction: row;
                                         justify-content: space-between;
                                         align-items: stretch;"
                    >
                        <read-more :classes="'pt-2'"
                                   :text="questionSelected.question"
                                   :max-chars="70"
                                   :font-size="20"
                        />
                        <v-btn text
                               :disabled="!(questionSelected.questionHelp && questionSelected.questionHelp.text)"
                               @click="showQuestionHelp = !showQuestionHelp"
                               color="primary"
                               rounded
                        >
                            <v-icon>
                                mdi-information-outline
                            </v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text class="pa-2">
                        <span v-if="questionSelected.questionHelp && questionSelected.questionHelp.text">
                            <v-alert v-if="showQuestionHelp"
                                     text
                                     dense
                                     color="primary"
                                     icon="mdi-information-outline"
                                     border="left"
                            >
                                {{ questionSelected.questionHelp.text }}
                            </v-alert>
                        </span>
                        <v-form ref="form" v-model="valid2">
                            <v-textarea 
                                        v-on:keyup.86="replaceAnswerName" v-on:keyup.ctrl.86="replaceAnswerName"
                                        onkeypress="return (event.charCode != 34)" color="primary"
                                        small flat solo background-color="#EAEAEA80"
                                        v-if="questionSelected.questionStructureType === 'TEXT'"
                                        counter
                                        v-model="questionSelected.answer_name"
                                        :auto-grow="true"
                                        :clearable="true"
                                        :no-resize="true"
                                        :row-height="40" :rows="3"
                                        :rules="questionSelected.isRequired? requireRules : []"
                                        @input="checkExtra(questionSelected)"
                            >
                                <template v-slot:label>
                                    {{$t('survey.fields.answer')}} <span v-if="questionSelected.isRequired" class="red--text"><strong>*</strong></span>
                                </template>
                            </v-textarea>
                            <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replaceAnswerName" v-on:keyup.ctrl.86="replaceAnswerName"
                                small flat solo background-color="#EAEAEA80"
                                v-if="questionSelected.questionStructureType === 'LOTE'   ||
                                      questionSelected.questionStructureType === 'LACRE'  ||
                                      questionSelected.questionStructureType === 'QR'"
                                color="primary" type="text"
                                v-model="questionSelected.answer_name"
                                :clearable="true"
                                :rules="questionSelected.isRequired? requireRules : []"
                                @input="checkExtra(questionSelected)"
                            >
                                <template v-slot:label>
                                    {{$t('survey.fields.answer')}} <span v-if="questionSelected.isRequired" class="red--text"><strong>*</strong></span>
                                </template>
                            </v-text-field>
                            <!-- MULTIPLES -->
                            <v-autocomplete :no-data-text="$t('general.fields.noDataAvailable')" color="primary" item-color="primary" clearable
                                            small flat solo background-color="#EAEAEA80"
                                            :items="questionSelected.possibleAnswer"
                                            item-text="answer_name"
                                            item-value="answer_name"
                                            small-chips
                                            multiple
                                            v-model="questionSelected.answers_names"
                                            required
                                            class="required"
                                            :error-messages="errorMaxPunctuation[questionSelected.id]"
                                            :rules="questionSelected.isRequired? selectRule : []"
                                            @change="checkExtra(questionSelected)"
                                            v-if="questionSelected.questionStructureType === 'PRODUCT'         ||
                                                  questionSelected.questionStructureType === 'EQUIPMENT'       ||
                                                  questionSelected.questionStructureType === 'OPERATOR'        ||
                                                  questionSelected.questionStructureType === 'INPUT'           ||
                                                  questionSelected.questionStructureType === 'MEASUREMENTUNIT' ||
                                                  questionSelected.questionStructureType === 'SEASON'          ||
                                                  questionSelected.questionStructureType === 'LIST'"
                            >
                                <template v-slot:label>
                                    {{$t('survey.fields.answer')}} <span v-if="questionSelected.isRequired" class="red--text"><strong>*</strong></span>
                                </template>
                                <template v-slot:item="{ item, attrs }">
                                    <div style="max-width:360px">
                                        <v-checkbox :input-value="attrs.inputValue"
                                                    color="primary"
                                                    class="pt-2 pb-2 mt-0"
                                                    :hide-details="true"
                                        >
                                            <template v-slot:label>
                                                <read-more :classes="'mb-0'"
                                                           :text="item.answer_name"
                                                           :max-chars="80"
                                                           :font-size="14"
                                                />
                                            </template>
                                        </v-checkbox>
                                    </div>
                                </template>
                                <template v-slot:selection="{ item, index }">
                                    <v-chip small v-if="index === 0">
                                        <span>{{ item.answer_name }}</span>
                                    </v-chip>
                                    <span
                                            v-if="index === 1"
                                            class="grey--text caption"
                                    >(+{{questionSelected.answers_names.length - 1}} {{$t('general.titles.others')}})</span>
                                </template>
                                <template v-slot:prepend-item>
                                    <v-list-item>
                                        <v-list-item-content class="py-0">
                                            <div class="row">
                                                <div class="col-6 py-0 text-right">
                                                    <v-btn small
                                                           @click="questionSelected.answers_names = selectOrNotAll(questionSelected, 'clear')"
                                                           color="primary" x-small
                                                           text>
                                                        {{$t('general.titles.selectAll')}}
                                                    </v-btn>
                                                </div>
                                                <v-divider vertical></v-divider>
                                                <div class="col-5 py-0 text-left">
                                                    <v-btn small
                                                           @click="questionSelected.answers_names = selectOrNotAll(questionSelected, 'clear')"
                                                           color="primary" x-small
                                                           text>
                                                        {{$t('general.titles.clean')}}
                                                    </v-btn>
                                                </div>
                                            </div>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mb-2"></v-divider>
                                </template>
                            </v-autocomplete>
                            <!-- SIMPLES -->
                            <v-autocomplete :no-data-text="$t('general.fields.noDataAvailable')" color="primary" item-color="primary" clearable
                                            small flat solo background-color="#EAEAEA80"
                                            :items="questionSelected.possibleAnswer"
                                            item-text="answer_name"
                                            item-value="answer_name"
                                            small-chips
                                            v-model="questionSelected.answers_names[0]"
                                            required
                                            class="required"
                                            :rules="questionSelected.isRequired? selectRule : []"
                                            @change="checkExtra(questionSelected)"
                                            v-if="questionSelected.questionStructureType === 'PRODUCTSIMPLE'         ||
                                                  questionSelected.questionStructureType === 'EQUIPMENTSIMPLE'       ||
                                                  questionSelected.questionStructureType === 'OPERATORSIMPLE'        ||
                                                  questionSelected.questionStructureType === 'INPUTSIMPLE'           ||
                                                  questionSelected.questionStructureType === 'MEASUREMENTUNITSIMPLE' ||
                                                  questionSelected.questionStructureType === 'SEASONSIMPLE'          ||
                                                  questionSelected.questionStructureType === 'LISTSIMPLE'"
                            >
                                <template v-slot:label>
                                    {{$t('survey.fields.answer')}} <span v-if="questionSelected.isRequired" class="red--text"><strong>*</strong></span>
                                </template>
                                <template v-slot:selection="{ item }">
                                    <v-chip small> {{ item.answer_name | truncate(30)}}</v-chip>
                                </template>
                                <template v-slot:item="{ item, attrs }">
                                    <div style="max-width:360px">
                                        <read-more :classes="'mb-0'"
                                                   :text="item.answer_name"
                                                   :max-chars="80"
                                                   :font-size="14"
                                        />
                                    </div>
                                </template>
                            </v-autocomplete>
                            <!--DATETIME-->
                            <v-datetime-picker :show-date="true"
                                               :show-time="true"
                                               :label="questionSelected.isRequired ? $t('survey.fields.answer') + `<strong class='red--text'>*</strong>` : $t('survey.fields.answer') "
                                               :locale="$i18n.locale"
                                               :text-field-props="textProps"
                                               color="primary"
                                               v-model="questionSelected.answers_names[0]"
                                               date-format="dd / MM / yyyy"
                                               @input="checkExtra(questionSelected)"
                                               :rules="questionSelected.isRequired? requireRules : []"
                                               v-if="questionSelected.questionStructureType==='DATATIME'"
                            />
                            <!--DATE-->
                            <v-datetime-picker :show-date="true"
                                               :label="questionSelected.isRequired ? $t('survey.fields.answer') + `<strong class='red--text'>*</strong>` : $t('survey.fields.answer') "
                                               :locale="$i18n.locale"
                                               :text-field-props="textProps"
                                               color="primary"
                                               v-model="questionSelected.answers_names[0]"
                                               date-format="dd / MM / yyyy"
                                               @input="checkExtra(questionSelected)"
                                               :rules="questionSelected.isRequired? requireRules : []"
                                               v-if="questionSelected.questionStructureType==='DATA'"
                            />
                            <!--TIME-->
                            <v-datetime-picker :show-time="true"
                                               :label="questionSelected.isRequired ? $t('survey.fields.answer') + `<strong class='red--text'>*</strong>` : $t('survey.fields.answer') "
                                               :locale="$i18n.locale"
                                               :text-field-props="textProps"
                                               color="primary"
                                               v-model="questionSelected.answers_names[0]"
                                               time-format="HH:mm"
                                               @input="checkExtra(questionSelected)"
                                               :rules="questionSelected.isRequired? requireRules : []"
                                               v-if="questionSelected.questionStructureType==='TIME'"
                                               :dialog-width="290"
                            />
                            <!-- <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replace" v-on:keyup.ctrl.86="replace"
                                    small flat solo background-color="#EAEAEA80"
                                    v-if="questionSelected.questionStructureType==='NUMBER'"
                                    color="primary" type="text"
                                    v-model="questionSelected.answers_names[0]"
                                    :rules="questionSelected.isRequired? requireRules.concat(onlyDecimalFormat) : onlyDecimalFormat"
                                    @input="checkExtra(questionSelected)"
                            >
                                <template v-slot:label>
                                    {{$t('survey.fields.answer')}} <span v-if="questionSelected.isRequired" class="red--text"><strong>*</strong></span>
                                </template>
                            </v-text-field> -->
                                <v-currency-field
                                    :default-value="null"
                                    :value-as-integer="false"
                                    :decimal-length="decimalFormat(questionSelected)"
                                    small flat solo background-color="#EAEAEA80"
                                    :auto-decimal-mode="false"
                                    locale="en"
                                    :allow-negative="false"
                                    v-if="questionSelected.questionStructureType==='NUMBER' ||
                                          questionSelected.questionStructureType==='IMO'"
                                    color="primary"
                                    :label="$t('survey.fields.punctuation')"
                                    :rules="questionSelected.isRequired? requireRules : []"
                                    v-model="questionSelected.answers_names[0]"
                                    @input="checkExtra(questionSelected)"
                                >
                                <template v-slot:label>
                                    {{$t('survey.fields.answer')}} <span v-if="questionSelected.isRequired" class="red--text"><strong>*</strong></span>
                                </template>
                            </v-currency-field>
                        </v-form>
                    </v-card-text>

                    <v-card-text class="pa-2">
                        <v-textarea 
                                onkeypress="return (event.charCode != 34)"
                                v-on:keyup.86="replaceComents" v-on:keyup.ctrl.86="replaceComents"
                                flat solo background-color="rgba(135,135,135,0.10)"
                                no-resize
                                color="primary"
                                :label="$t('inspections.labels.label_textarea')"
                                v-model="questionSelected.coments"
                                auto-grow
                                counter
                        />
                    </v-card-text>


                    <v-card-text class="pa-2">
                        <v-form ref="form3">
                            <v-file-input multiple @change="addDocument" color="primary" v-model="filesSelected"
                                          :rules="fileTypeRulesAndSize(filesSelected)"
                                          label="File input" small-chips>
                            </v-file-input>
                        </v-form>
                    </v-card-text>
                    <v-row align-content="center"
                           justify="center"
                           v-if="questionSelected.evidences && questionSelected.evidences.length > 0">
                        <v-col cols="8">
                            <v-card v-show="loadingDoc" flat>
                                <v-card-title style="font-weight: 300; font-size: 14px">
                                    {{ $t('general.ui.loading_evidences') }}
                                </v-card-title>
                                <v-card-text>
                                    <v-progress-linear
                                            color="secondary accent-4"
                                            indeterminate
                                            rounded
                                            height="6"
                                    />
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-card-text :loading="documentLoad" class="mb-12" height="350px" elevation="0">
                        <v-list class="overflow-y-auto" max-height="300">
                            <v-list-item v-for="(doc, index) in documentsUpload" v-bind:key="index">
                                <v-list-item-avatar>
                                    <v-icon>mdi-file-image-outline</v-icon>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title v-text="doc.name"/>
                                </v-list-item-content>

                                <v-list-item-icon color="primary">
                                    <v-icon @click="deleteFile(doc, index)">
                                        mdi-minus-circle-outline
                                    </v-icon>
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text color="gray" @click="closeDialogQuestionEdit(documentsUpload)">
                            {{$t('general.buttons.cancel')}}
                        </v-btn>
                        <v-btn text color="primary" @click="saveProcessQuestion()" :disabled="!valid2">
                            {{$t('general.buttons.save')}}
                        </v-btn>
                    </v-card-actions>
                    <v-fade-transition>
                        <v-overlay
                                v-if="loadingSave"
                                absolute
                                color="white"
                        >
                            <v-progress-circular
                                    indeterminate
                                    size="64"
                                    color="primary"
                            />
                        </v-overlay>
                    </v-fade-transition>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import _ from 'lodash';
    import PermisionService from "../../services/PermisionService";
    import moment from 'moment';
    import i18n from '@/plugins/i18n';
    import SurveyService from "../../services/SurveyService";
    import LanguajeService from "../../services/LanguajeService";
    import sortList from "../../services/OrderListService";
    import ReadMore from "../../components/ReadMoreComponent";
    import CompactImages from '../../services/CompactImages'

    const STRUCTURE_TYPE = {
        MULTIPLE: [
            'PRODUCT',
            'EQUIPMENT',
            'OPERATOR',
            'INPUT',
            'MEASUREMENTUNIT',
            'SEASON',
            'LIST'
        ],
        SIMPLE: [
            'NUMBER',
            'TEXT',
            'QR',
            'LOTE',
            'LACRE',
            'TIME',
            'DATA',
            'DATATIME',
            'PRODUCTSIMPLE',
            'EQUIPMENTSIMPLE',
            'OPERATORSIMPLE',
            'INPUTSIMPLE',
            'MEASUREMENTUNITSIMPLE',
            'SEASONSIMPLE',
            'LISTSIMPLE'
        ]
    };

    export default {
        name: "Survey",
        components: {ReadMore},
        data: () => ({
            questionData: {},
            filesSelected: [],
            textProps: {
                flat: true,
                solo: true,
                backgroundColor: '#EAEAEA80'
            },
            showDialogComment: false,
            readMoreActivated: false,
            showQuestionHelp: false,
            dateFormat: 'DD/MM/YYYY',
            editedIndexSurvey: -1,
            ready: false,
            profile: {},
            answer: {
                "question": "",
                "questionHelp": {},
                "answer": ""
            },
            title: 'Información',
            dialogText: 'Añada un texto a este dialogo',
            location_origin: null,
            itemsActions: [
                {title: i18n.t('general.buttons.edit'), icon: 'mdi-square-edit-outline', action: 'EDIT'},
                {title: i18n.t('general.buttons.downloadEvidence'), icon: 'mdi-download-outline', action: 'DOWNLOAD'}
            ],
            coment_text: null,
            sectionSelected: null,
            documentsUpload: [],
            AlldocumentsUpload: [],
            AllTempDocumentsUpload: [],
            documentsUploadName: [],
            dialogUpload: false,
            editDialog: false,
            documentLoad: false,
            valid2: false,
            updating: false,
            loadingDoc: false,
            loadingSave: false,
            showResponsable: false,
            loadingMoreOptions: {},
            errorMaxPunctuation: {},
            errorPunctuation: false
        }),

        computed: {
            questionSelected: {
                get() {
                    return this.$store.getters['inspections/question'];
                },
                set(val) {
                    this.$store.commit('inspections/SET_QUESTION', val)
                }
            },
            inspectionSelected: {
                get() {
                    return this.$store.getters['inspections/inspection'];
                },
                set(val) {
                    this.$store.commit('inspections/SET_INSPECTION', val)
                }
            },
            ...mapGetters({
                loading: 'ui/GET_LOADING',
                locationsList: 'locations/locationsList',
                requireAndMaxRules: 'general/requireAndMaxRules',
                requireRules: 'general/requireRules',
                selectRule: 'general/selectRule',
                fileTypeRulesAndSize: 'general/fileTypeRulesAndSize',
                onlyDecimalFormat: 'general/onlyDecimalFormat',
                languageStructure: 'general/languageStructure'
            }),
            config: {
                get() {
                    return this.$store.getters['general/config']
                },
                set(val) {
                    this.$store.commit('general/SET_CONFIG', val)
                }
            },
            decimalFormat() {
                return (question) => question.afterPoint !== null
                    ? question.afterPoint
                    : this.config.company.traceability.structure.number.afterPoint !== null
                        ? this.config.company.traceability.structure.number.afterPoint
                        : 3
            },
        },

        created() {
            this.config = JSON.parse(localStorage.getItem('config'))
            this.initialize()
        },

        methods: {
            ...mapActions({
                saveQuestion: 'inspections/saveQuestion',
                fetchInspectionById: 'inspections/fetchInspectionById'
            }),
            selectOrNotAll(question, action) {
                let answers_names = [];

                if(action === 'all') {
                    _.forEach(question.possibleAnswer, (answer) => {
                        answers_names.push(answer.answer_name)
                    })
                }

                question.answers_names = answers_names;
                this.maxPunctuation(question)

                return answers_names;
            },
            maxPunctuation(question) {
                if (question.moreAnswerPointsThanQuestion === false && question.answers_names.length > 1){
                    let punctuation = 0;
                    _.forEach(question.answers_names, (name) => {
                        let item = _.find(question.possibleAnswer, { 'answer_name': name })
                        punctuation += item.punctuation;
                    });
                    if (punctuation > question.questionPunctuation) {
                        this.$set(this.errorMaxPunctuation, question.id, this.$t('inspections.rules.max_points_by_question'))
                        this.errorPunctuation = true;
                    } else {
                        this.$set(this.errorMaxPunctuation, question.id, '')
                        this.errorPunctuation = false;
                    }
                } else {
                    this.$set(this.errorMaxPunctuation, question.id, '')
                    this.errorPunctuation = false;
                }
            },
            transformAnswers(question, answers) {
                if (STRUCTURE_TYPE.SIMPLE.includes(question.questionStructureType)) {
                    let dateFormatted = (LanguajeService.getKey3(answers[0].answer)+'.000Z').slice(0, 19);
                    return question.questionStructureType === 'DATA'     ? moment(dateFormatted).format('DD / MM / YYYY')          :
                           question.questionStructureType === 'TIME'     ? moment(dateFormatted).format('HH:mm')                :
                           question.questionStructureType === 'DATATIME' ? moment(dateFormatted).format('DD / MM / YYYY HH:mm') :
                           LanguajeService.getKey3(answers[0].answer)
                }
                if (STRUCTURE_TYPE.MULTIPLE.includes(question.questionStructureType)) {
                    let text = [];
                    _.forEach(answers, (answer) => {
                        text.push(LanguajeService.getKey3(answer.answer))
                    });
                    return _.sortBy(text).join(', ')
                }
            },
            closeDialogQuestionEdit(docs) {
                _.forEach(docs, (doc, index) => {
                    this.AlldocumentsUpload.splice(index, 1);
                });
                this.filesSelected = [];
                this.documentsUploadName = [];
                this.documentsUpload = [];
                this.questionSelected.documents = [];
                this.showQuestionHelp = false;
                this.editDialog = false;
            },
            deleteFile(doc, index) {
                if (this.editedIndexSurvey > 0) {
                    this.AlldocumentsUpload.splice(index, 1);
                }
                this.documentsUpload.splice(index, 1);
                this.documentsUploadName.splice(index, 1);
                if (this.documentsUpload.length === 0) {
                    this.questionSelected.evidences = [];
                    this.filesSelected = []
                }
                this.updating = true;
            },
            dialogTemplateClose(option) {
                if (option) {
                    this.showDialogComment = false;
                    this.coment_text = '';
                } else {
                    this.dialogUpload = false;
                    this.documentsUploadName = [];
                    this.documentsUpload = [];
                }
            },
            getEvidencesToShow(questionId) {
                const scope = this;
                try {
                    this.documentLoad = true;
                    SurveyService.getEvidencas(questionId).then(response => {
                        const blob = new Blob([response.data], {
                            type: 'application/zip'
                        });
                        const jsZip = require('jszip');
                        jsZip.loadAsync(blob).then(function (zip) {
                            Object.keys(zip.files).forEach(function (filename) {
                                zip.files[filename].async('blob').then(function (fileData) {
                                    // These are your file contents
                                    let type = '';
                                    const nameSpliter = filename.split('.');
                                    if (nameSpliter[1] === 'pdf' || nameSpliter[1] === 'PDF') {
                                        type = "application/pdf"
                                    } else {
                                        type = "image/" + nameSpliter[1];
                                    }
                                    scope.addDocument([new File([fileData], filename, {type: type})], true);
                                })
                            });
                            scope.documentLoad = false;
                        });
                    }).finally(() => {
                        this.loadingDoc = false
                    });
                } catch (error) {
                    this.$toast.info(i18n.t('survey.notification.evidencesCero'), {
                      icon: 'mdi-information-slab-circle',
                        queueable: true
                    });
                }

            },
            addDocument(docs, load) {
                const scope = this;
                this.updating = !load;
                const mimeType = ['image/jpeg', 'image/jpg', 'image/bmp', 'image/png', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword', 'text/csv', 'application/pdf', 'application/zip', 'application/x-zip-compressed', 'application/vnd.rar'];
                _.forEach(docs, (doc) => {
                    CompactImages.downscaleImage( 0.7, doc, function( data ){
                        if (load || (data.size <= 5000000 && mimeType.includes(data.type))) {
                            let dt = new Date().getTime();
                            let uuid = 'agdoc_' + scope.questionSelected.id + 'xxxxxx'.replace(/[xy]/g, (c) => {
                                let r = (dt + Math.random() * 16) % 16 | 0;
                                dt = Math.floor(dt / 16);
                                return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
                            });
                            uuid = uuid + '.' + data.name.split('.').pop();
                            let fileRename = new File([data], uuid, {
                                type: data.type,
                                lastModified: data.lastModified,
                            });
                            scope.documentsUpload.push(fileRename);
                            scope.AlldocumentsUpload.push(fileRename);
                            scope.documentsUploadName.push(uuid);
                        }
                    });
                });
                // this.documentsUpload = null;
            },
            openDialogQuestion(item) {
                this.questionSelected = item;
                this.updating = false;
                if (this.questionSelected.questionStructureType === 'NUMBER') {
                    this.questionSelected.answers_names[0] = '';
                }
                if (this.editedIndexSurvey > 0 && !this.questionSelected.updating && (this.questionSelected.evidences && this.questionSelected.evidences.length > 0)) {
                    this.loadingDoc = true;
                    this.getEvidencesToShow(this.questionSelected.id);
                } else {
                    this.loadingDoc = false;
                    this.documentsUploadName = this.questionSelected.documents;
                    this.AlldocumentsUpload.forEach(d => {
                        if (this.documentsUploadName.find(un => un === d.name)) {
                            this.documentsUpload.push(d);
                        }
                    });
                }
                this.editDialog = true;
            },
            launchAction(action, item) {
                switch (action) {
                    case 'EDIT':
                        this.openDialogQuestion(item);
                        break;
                    case 'DOWNLOAD':
                        this.getEvidencesToDownload(item.id);
                        break;
                }
            },
            openComment(item) {
                this.questionData = item;
                this.showDialogComment = true;
            },
            initialize() {
                this.ready = false;
                this.profile = JSON.parse(localStorage.getItem('profile'));
                this.editedIndexSurvey = JSON.parse(localStorage.getItem("editedIndexSurvey"));

                this.inspectionSelected.questionSectionList = _.map(this.inspectionSelected.questionSectionList, (section) => {
                    return {
                        ...section,
                        questions: _.map(section.questions, (question) => {
                            if (question.questionStructureType === 'NUMBER') {
                                if(!(question.answers_names && question.answers_names.length > 0)) {
                                    question.answers_names[0] = '';
                                }
                            }
                            return {
                                ...question,
                                possibleAnswer: question.possibleAnswer && question.possibleAnswer.length > 0 ? sortList.orderListByUppercase(question.possibleAnswer, 'answer_name') : []
                            }
                        })
                    }

                });

                const location = (this.inspectionSelected.locations && this.inspectionSelected.locations.length > 0) ? this.inspectionSelected.locations[0].primaryLocation : '';
                this.location_origin = location ? location : null;
                this.ready = true;
            },
            checkExtra(question) {
                if (STRUCTURE_TYPE.MULTIPLE.includes(question.questionStructureType)) {
                    this.maxPunctuation(question);
                }
                const questionAnswerConvert = question && typeof question === "object" ? question.answer : question;
                return questionAnswerConvert === question.conditionalAnswer;
            },

            getDateFormat(dateString) {
                return moment(new Date(dateString)).format(this.dateFormat);
            },

            checkAccess(section) {
                return !!_.find(section.responsables, {id: this.profile.id});
            },

            async getEvidencesToDownload(questionId) {
                this.$set(this.loadingMoreOptions, questionId, true);
                try {
                    await SurveyService.getEvidencas(questionId).then(response => {
                        const FileSaver = require('file-saver');
                        const blob = new Blob([response.data], {
                            type: 'application/zip'
                        });
                        FileSaver.saveAs(blob, 'evidences.zip');
                    }).finally(() => {
                        this.$set(this.loadingMoreOptions, questionId, false);
                    });
                } catch (error) {
                    this.$toast.info(i18n.t('survey.notification.evidencesCero'), {
                        icon: 'mdi-information-slab-circle',
                        queueable: true
                    });
                }
            },

            back() {
                this.$router.push({"name": "inspections"});
            },

            // Inspection Percent
            surveyAverage() {
                return this.inspectionSelected.surveyPercentPunctuation;
            },
            // Question Section Percent
            sectionAverage(section) {
                return section.questionSectionPercentPunctuation;
            },

            check(permisions) {
                return PermisionService.check(permisions);
            },

            setQuestionHelp(questionHelp) {
                return questionHelp;
            },

            setPosibleAnswers(posibleAnswers) {
                let posibleAnwersMap = [];
                _.forEach(posibleAnswers, (posibleAnswer) => {
                    posibleAnwersMap.push(posibleAnswer);
                });
                return posibleAnwersMap;
            },

            getAnswerTreatment(question) {
                let answers = [];

                //ONLY FOR STRUCTURES TYPE NUMBER
                if (question.questionStructureType === 'NUMBER'                  &&
                    (question.answers_names && question.answers_names.length > 0) &&
                    question.answers_names[0] === '')
                {
                    question.answers_names = [];
                }
                if (STRUCTURE_TYPE.SIMPLE.includes(question.questionStructureType) && (question.answers_names && question.answers_names.length > 0)) {
                    const STRUCTURE = ['DATA', 'TIME', 'DATATIME'];
                    let data = STRUCTURE.includes(question.questionStructureType) ? moment(question.answers_names[0]).format().slice(0, 19) : question.answers_names[0];

                  let answerSelected = null
                  if (!['TIME', 'DATA', 'DATATIME',].includes(question.questionStructureType)) {
                    answerSelected = _.find(question.possibleAnswer, {
                      answer_name: data,
                    })
                  }

                    answers.push({
                        answerPunctuation: answerSelected
                            ? answerSelected.punctuation
                            : answers && answers.length > 0
                                ? question.questionPunctuation
                                : 0,
                        answer: LanguajeService.setKey3(data, question.answers && question.answers.length > 0 ? question.answers[0].answer : data, this.editedIndexSurvey > -1 ? null : this.languageStructure)
                    });
                } else {
                    _.forEach(question.answers_names , (name, index) => {
                        const answerSelected = _.find(question.possibleAnswer, {'answer_name':name});
                        const comparison = (question.answers        && question.answers                     .length > 0) &&
                                           (question.answers[index] && question.answers[index]              .length > 0) &&
                                           (question.answers[index].answer && question.answers[index].answer.length > 0)
                        ;
                        answers.push({
                            answerPunctuation: answerSelected ? answerSelected.punctuation: 0,
                            answer: LanguajeService.setKey3(
                                name,
                                comparison ? question.answers[index].answer : name,
                                this.editedIndexSurvey > -1 && comparison ? null : this.languageStructure
                            )
                        });
                    });
                }
                return answers;
            },

            async saveProcessQuestion() {

                this.loadingSave = true;
                const answers = this.getAnswerTreatment(this.questionSelected);

                this.questionSelected.documents = this.documentsUploadName;
                this.questionSelected.updating = this.updating ? this.updating : this.questionSelected.updating;

                let answers_names = [];

                const STRUCTURE = ['DATATIME', 'TIME', 'DATA'];
                if (STRUCTURE.includes(this.questionSelected.questionStructureType)) {
                    _.forEach(answers, (answer) => {
                        let dateFormatted = (LanguajeService.getKey3(answer.answer) + '.000Z').slice(0, 19);
                        let data = new Date(dateFormatted);
                        answers_names.push(data);
                    });
                }

                this.questionSelected = {
                    ...this.questionSelected,
                    answers: answers,
                    answers_names: answers_names,
                    questionHelp: !this.questionSelected.questionHelp ? {} : this.setQuestionHelp(this.questionSelected.questionHelp),
                    possibleAnswer: !this.questionSelected.possibleAnswer ? this.languageStructure : this.setPosibleAnswers(this.questionSelected.possibleAnswer),
                    responsable: answers.length > 0 && this.questionSelected.show ? (this.questionSelected.responsable ? this.questionSelected.responsable : null) : {id: this.profile.id},
                };

                this.loadingSave = false;
                this.editDialog = false;

                await this.saveQuestion([this.questionSelected, this.AlldocumentsUpload, this.$toast]).finally( () => {
                    this.filesSelected = [];
                    this.documentsUploadName = [];
                    this.documentsUpload = [];
                });

                await this.fetchInspectionById([this.editedIndexSurvey, this.$toast])
            },
            translate(key) {
                return LanguajeService.getKey3(key)
            },
            replace(event){
                event.currentTarget.value =  event.currentTarget.value.toString().replaceAll('"','')
            },
            replaceAnswerName(event){
                this.questionSelected.answer_name =  event.currentTarget.value.toString().replaceAll('"','')
            },
            replaceComents(event){
                this.questionSelected.coments =  event.currentTarget.value.toString().replaceAll('"','')
            },
        }
    }
</script>

<style scoped>
</style>
